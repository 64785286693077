import { Box } from '@mui/material';
import './main.scss';

export const Main = () => {
  return (
    <Box className="main-page__header">
      <h2 className="header__title">Kubernetes panel</h2>
    </Box>
  );
};
