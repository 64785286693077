import { InfoOutlined } from '@mui/icons-material';
import { Autocomplete } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { MyTextField } from '../my-text-field/my-text-field';
import './editable-dialog.scss';

export type EditableDialogInput<T> = IEditableDialogInputTextField<T> | IEditableDialogInputAutocomplete<T>;

interface IEditableDialogInputTextField<T> {
  title: string;
  key: T;
  type?: 'text-field';
  default_value: string;
}

interface IEditableDialogInputAutocomplete<T> {
  title: string;
  key: T;
  type: 'autocomplete';
  options: string[];
  default_value: string;
}

interface IProps<T extends string> {
  open: boolean;
  header: string;
  inputs: Readonly<EditableDialogInput<T>[]>;
  onOk: (data: { [key in T]: string }) => void;
  onCancel: () => void;
  tooltip?: string;
}

export type TEditableDialogInputs<T extends Readonly<Array<{ key: string }>>> = { [key in T[number]['key']]: string };

export const EditableDialog = <T extends string>({ open, header, inputs, onOk, onCancel, tooltip }: IProps<T>) => {
  const defaults = inputs.reduce((acc, { key, default_value }) => {
    acc[key] = default_value;
    return acc;
  }, {} as TEditableDialogInputs<typeof inputs>);

  return (
    <div>
      <Dialog open={open} onClose={onCancel} className="dialog">
        <DialogTitle className="dialog__title">{header}</DialogTitle>
        <DialogContent>
          {inputs.map((input) => {
            const { title, key, type } = input;
            if (type === 'autocomplete') {
              return (
                <Autocomplete
                  key={key}
                  options={input.options}
                  freeSolo
                  value={[defaults[key]]}
                  onInputChange={(_, value) => (defaults[key] = value as string)}
                  renderInput={(params) => <MyTextField {...params} label={title} />}
                />
              );
            } else {
              return (
                <MyTextField
                  key={key}
                  label={title}
                  defaultValue={defaults[key]}
                  onChange={({ target: { value } }) => (defaults[key] = value)}
                  icon={<InfoOutlined />}
                  icon_tooltip={tooltip}
                />
              );
            }
          })}
        </DialogContent>
        <DialogActions className="dialog__buttons">
          <Button onClick={onCancel} className="dialog__btn">
            Cancel
          </Button>
          <Button onClick={() => onOk(defaults)} className="dialog__btn">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
