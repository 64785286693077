import { MoreVert } from '@mui/icons-material';
import { Box, Chip, CircularProgress, Input } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { EUserPermissions } from '../../app/enums';
import { withAccess } from '../../auth-context/can-access';
import { api } from '../../helpers/api.helper';
import { IDeployment } from '../../interfaces';
import { DropdownMenu } from '../dropdown-menu/dropdown-menu';
import { EditableDialog, TEditableDialogInputs } from '../editable-dialog/editable-dialog';
import { Header } from '../header/header';
import { ViewDialog } from '../view-dialog/view-dialog';
import './deployment.scss';

const DeploymentComponent = () => {
  const { project_id } = useParams<{ project_id: string }>();

  const [currentProjectName, setCurrentProjectName] = useState('');
  const [deployments, setDeployments] = useState<IDeployment[]>([]);
  const [filteredDeployments, setFilteredDeployments] = useState<IDeployment[]>([]);

  const [deployResponseData, setDeployResponseData] = useState<Array<{ id: string; name: string; message: string }>>(
    []
  );

  const [withEditModal, setWithEditModal] = useState(false);
  const [deploymentData, setDeploymentData] = useState<IDeployment | null>(null);
  const [deployingServices, setDeployingServices] = useState(new Set<string>());
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    api.deployment.list(project_id).then(({ data: { deployments, project_name } }) => {
      setDeployments(deployments);
      setCurrentProjectName(project_name);
    });
  }, []);

  useEffect(() => {
    setFilteredDeployments(
      deployments.filter((deploy) => deploy.display_name.toLowerCase().includes(searchValue.toLowerCase()))
    );
  }, [deployments, searchValue]);

  const isDeploymentEdit = deploymentData?.id;

  const inputs = [
    { title: 'Title', key: 'display_name', default_value: deploymentData?.display_name || '' },
    { title: 'Deployment name', key: 'name', default_value: deploymentData?.name || '' },
  ] as const;

  const editDeployment = (id: string, name: string, display_name: string) => {
    api.deployment.edit(id, name, display_name).then((v) => {
      if (v.data.id) {
        setDeployments((deployments) => deployments.map((d) => (d.id === v.data.id ? { ...d, ...v.data } : d)));
      }
    });
  };

  const createDeployment = (name: string, display_name: string) => {
    api.deployment.create(project_id, name, display_name).then((v) => {
      if (v.data.id) {
        setDeployments((deployments) => deployments.concat(v.data));
      }
    });
  };

  const deleteDeployment = (id: string) => {
    api.deployment.delete(id).then(() => {
      setDeployments((deployments) => deployments.filter((deployment) => deployment.id !== id));
    });
  };

  const onEditModalOk = (obj: TEditableDialogInputs<typeof inputs>) => {
    if (isDeploymentEdit) {
      editDeployment(deploymentData.id, obj.name, obj.display_name);
    } else {
      createDeployment(obj.name, obj.display_name);
    }

    setDeploymentData(null);
    setWithEditModal(false);
  };

  const onEditModalCancel = () => setWithEditModal(false);

  const openEditModal = (data: IDeployment | null) => {
    setDeploymentData(data);
    setWithEditModal(true);
  };

  const deploy = (id: string, name: string) => {
    setDeployingServices((prev) => new Set(prev).add(id));
    api.deploy
      .service(id)
      .then((v) => {
        setDeployResponseData((deployResponseData) => deployResponseData.concat({ id, name, message: v.data }));
      })
      .catch((e) => {
        setDeployResponseData((deployResponseData) => deployResponseData.concat({ id, name, message: e.message }));
      })
      .finally(() => {
        setDeployingServices((prevState) => new Set([...prevState].filter((x) => x !== id)));
      });
  };

  const isDeployingComplete = useCallback(
    (services: Array<{ id: string }>) => {
      return !!services.map((serv) => deployingServices.has(serv.id)).filter((id) => id).length;
    },
    [deployingServices]
  );

  return (
    <>
      <Header
        title={currentProjectName}
        mainBtnHandler={() => openEditModal(null)}
        isSave={false}
        backLink={'/projects'}
      />
      <div className="search">
        <Input onChange={(event) => setSearchValue(event.target.value)} placeholder="Deploy search" />
      </div>
      {!!filteredDeployments.length && (
        <Box className="box">
          <ul className="box__list">
            {filteredDeployments.map((deployment) => (
              <li key={deployment.id} className="box__item">
                <Link to={`${deployment.id}`} className="box__link">
                  {deployment.display_name}/{deployment.name}
                </Link>
                {!!deployment.services.length && (
                  <>
                    {deployment.services.map(({ id, env }) => (
                      <Chip
                        key={id}
                        label={env}
                        className="box__chip"
                        icon={deployingServices.has(id) ? <CircularProgress size={20} /> : <span />}
                      />
                    ))}
                    <DropdownMenu
                      isLoading={isDeployingComplete(deployment.services)}
                      buttonElement={'Deploy'}
                      items={deployment.services.map(({ id, env }) => ({
                        text: env,
                        onClick: () => deploy(id, deployment.display_name),
                      }))}
                    />
                  </>
                )}
                <DropdownMenu
                  buttonElement={<MoreVert />}
                  items={[
                    { text: 'Edit', onClick: () => openEditModal(deployment) },
                    { text: 'Delete', onClick: () => deleteDeployment(deployment.id) },
                  ]}
                />
              </li>
            ))}
          </ul>
        </Box>
      )}
      <EditableDialog
        open={withEditModal}
        header={isDeploymentEdit ? 'Edit deployment' : 'Create deployment'}
        inputs={inputs}
        onOk={onEditModalOk}
        onCancel={onEditModalCancel}
      />
      {!!deployResponseData.length && (
        <ViewDialog
          open={!!deployResponseData[0]}
          header={deployResponseData[0].name}
          key={deployResponseData[0].id}
          onClose={() => {
            setDeployResponseData(deployResponseData.filter((d) => d.id !== deployResponseData[0].id));
          }}
        >
          <p style={{ whiteSpace: 'pre' }}>{deployResponseData[0].message}</p>
        </ViewDialog>
      )}
    </>
  );
};

export const DeploymentPage = withAccess(EUserPermissions.PROJECTS)(DeploymentComponent);
