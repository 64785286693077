import { InputAdornment, TextField, TextFieldProps, Tooltip } from '@mui/material';
import { FC, ReactElement } from 'react';
import './my-text-field.scss';

export type MyTextFieldProps = TextFieldProps & {
  icon?: ReactElement;
  icon_tooltip?: string;
  onChangeValue?: (value: string) => void;
};

export const MyTextField: FC<MyTextFieldProps> = ({ icon, icon_tooltip, onChangeValue, InputProps, ...props }) => {
  let additional_input_props: TextFieldProps['InputProps'] = {};

  if (icon && icon_tooltip) {
    additional_input_props = {
      endAdornment: (
        <Tooltip title={<div className="tooltip">{icon_tooltip}</div>}>
          <InputAdornment position="end">{icon}</InputAdornment>
        </Tooltip>
      ),
    };
  }

  const additional_props: TextFieldProps = {};

  if (props.type === 'number') {
    additional_props.onKeyDown = (event) => {
      if ([188, 189, 190, 110, 109, 107, 69].includes(event.which)) {
        event.preventDefault();
      }
    };
  }

  if (onChangeValue) {
    additional_props.onChange = ({ target: { value } }) => onChangeValue(value);
  }

  return (
    <TextField
      margin="dense"
      type="text"
      fullWidth
      variant="outlined"
      {...props}
      {...additional_props}
      InputProps={{ ...additional_input_props, ...InputProps }}
    />
  );
};
