import { EUserPermissions } from '../app/enums';

export interface IJWT {
  id: string;
  exp_date: string;
  /** Время истечения токена */
  exp: number;
  /** Время создания токена */
  iat: number;
  /** Пермишены пользователя */
  permissions: EUserPermissions[];
}

export function getCookie(key: string): string {
  const all_cookies = document.cookie.split(';');
  for (const cookie of all_cookies) {
    const kv_pair = cookie.split('=');
    if (kv_pair[0].trim() === key) {
      return decodeURIComponent(kv_pair[1]);
    }
  }
  return '';
}

interface ICookieOptions {
  maxAge?: number;
  expires?: string | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
}

export function putCookie(key: string, value: string, options?: ICookieOptions): string {
  let cookie_string = `${key}=${value};`;

  if (options) {
    if (options.maxAge) {
      cookie_string += ` maxAge=${options.maxAge};`;
    } else if (options.expires instanceof Date) {
      cookie_string += ` expires=${options.expires.toUTCString()};`;
    } else if (!options.expires) {
      cookie_string += ` expires=${options.expires};`;
    }
    if (options.path) {
      cookie_string += ` path=${options.path};`;
    }
    if (options.domain && !(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')) {
      cookie_string += ` domain=${options.domain};`;
    }
    if (options.secure) {
      cookie_string += ' ;secure';
    }
  }

  document.cookie = cookie_string;
  return document.cookie;
}

export function parseJWT(raw_jwt: string): IJWT | null {
  if (raw_jwt) {
    try {
      const base64 = raw_jwt.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
      return JSON.parse(decodeURIComponent(escape(window.atob(base64))));
    } catch (e) {
      return null;
    }
  }
  return null;
}

export function removeJWT() {
  document.cookie = 'JWT=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  // document.cookie = `JWT=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${environment.cookie_domain}`;
}
