import { Close, MoreVert } from '@mui/icons-material';
import { Box, Chip, IconButton, Input } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { EUserPermissions } from '../../app/enums';
import { withAccess } from '../../auth-context/can-access';
import { api } from '../../helpers/api.helper';
import { IProject } from '../../interfaces';
import { DropdownMenu } from '../dropdown-menu/dropdown-menu';
import { EditableDialog, EditableDialogInput, TEditableDialogInputs } from '../editable-dialog/editable-dialog';
import { Header } from '../header/header';
import './projects.scss';

const filterInitialName = 'Filter namespaces';

const ProjectsComponent = () => {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [availableNamespaces, setAvailableNamespaces] = useState<string[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<IProject[]>([]);
  const [filterName, setFilterName] = useState(filterInitialName);

  const [withEditModal, setWithEditModal] = useState(false);
  const [projectData, setProjectData] = useState<IProject | null>(null);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    api.project.list().then(({ data }) => {
      setProjects(data);

      const uniqNamespaces = data.reduce((acc, item) => {
        acc.add(item.namespace);
        return acc;
      }, new Set<string>());

      setAvailableNamespaces(Array.from(uniqNamespaces));
    });
  }, []);

  useEffect(() => {
    setFilteredProjects(
      projects.filter((project) => {
        if (filterName !== filterInitialName) {
          return (
            project.namespace === filterName && project.display_name.toLowerCase().includes(searchValue.toLowerCase())
          );
        } else {
          return project.display_name.toLowerCase().includes(searchValue.toLowerCase());
        }
      })
    );
  }, [projects, searchValue]);

  const isProjectEdit = projectData?.id;

  const inputs: Readonly<EditableDialogInput<keyof IProject>[]> = [
    { title: 'Project name', key: 'display_name', default_value: projectData?.display_name || '' },
    {
      title: 'Namespace',
      key: 'namespace',
      default_value: projectData?.namespace || '',
      type: 'autocomplete',
      options: availableNamespaces,
    },
  ] as const;

  const editProject = (id: string, display_name: string, namespace: string) => {
    api.project.edit(id, display_name, namespace).then((v) => {
      if (v.data.id) {
        setProjects((projects) => projects.map((p) => (p.id === v.data.id ? { ...p, ...v.data } : p)));
      }
    });
  };

  const createProject = (display_name: string, namespace: string) => {
    api.project.create(display_name, namespace).then((v) => {
      if (v.data.id) {
        setProjects((projects) => projects.concat(v.data));
      }
    });
  };

  const deleteProject = (id: string) => {
    api.project.delete(id).then(() => {
      setProjects((projects) => projects.filter((project) => project.id !== id));
    });
  };

  const onEditModalOk = (obj: TEditableDialogInputs<typeof inputs>) => {
    console.log('sdasd', obj);
    if (isProjectEdit) {
      editProject(projectData.id, obj.display_name, obj.namespace);
    } else {
      createProject(obj.display_name, obj.namespace);
    }

    setProjectData(null);
    setWithEditModal(false);
  };

  const onEditModalCancel = () => setWithEditModal(false);

  const openEditModal = (data: IProject | null) => {
    setProjectData(data);
    setWithEditModal(true);
  };

  const namespacesFilter = (name: string) => {
    setFilteredProjects(projects.filter((project) => project.namespace === name));
    setFilterName(name);
  };

  const resetNamespacesFilter = () => {
    setFilteredProjects(projects);
    setFilterName(filterInitialName);
  };

  const items = useMemo(() => {
    return availableNamespaces.map((name) => ({
      text: name,
      onClick: () => namespacesFilter(name),
    }));
  }, [availableNamespaces]);

  return (
    <div className="projects">
      <Header title={'Projects'} mainBtnHandler={() => openEditModal(null)} isSave={false} />
      <div className="projects__toolbar">
        <div className="projects__filter">
          <DropdownMenu buttonElement={filterName} items={items} />
          {filterName !== filterInitialName && (
            <IconButton onClick={resetNamespacesFilter} size="small">
              <Close />
            </IconButton>
          )}
        </div>
        <Input onChange={(event) => setSearchValue(event.target.value)} placeholder="Projects search" />
      </div>

      {!!filteredProjects.length && (
        <Box className="projects__box box">
          <ul className="box__list">
            {filteredProjects.map((project) => (
              <li key={project.id} className="box__item">
                <Link to={`/projects/${project.id}`} className="box__link">
                  {project.display_name}
                </Link>
                <Chip label={project.namespace} className="box__chip" />
                <DropdownMenu
                  buttonElement={<MoreVert />}
                  items={[
                    { text: 'Edit', onClick: () => openEditModal(project) },
                    { text: 'Delete', onClick: () => deleteProject(project.id) },
                  ]}
                />
              </li>
            ))}
          </ul>
        </Box>
      )}
      <EditableDialog
        open={withEditModal}
        header={isProjectEdit ? 'Edit project' : 'Create project'}
        inputs={inputs}
        onOk={onEditModalOk}
        onCancel={onEditModalCancel}
        tooltip="Имя проекта - имя, которое отображается в k8s-utils и более нигде не задействуется"
      />
    </div>
  );
};

export const ProjectsPage = withAccess(EUserPermissions.PROJECTS)(ProjectsComponent);
