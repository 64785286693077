import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';

interface IProps {
  open: boolean;
  header: string;
  children: React.ReactNode;
  onClose: () => void;
  onSubmit: () => void;
  textClose: string;
  textOk: string;
}

export const DialogChoose = ({ open, header, onClose, onSubmit, children, textClose, textOk }: IProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{header}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{textClose}</Button>
        <Button onClick={onSubmit}>{textOk}</Button>
      </DialogActions>
    </Dialog>
  );
};
