import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './accordion-wrapper.scss';

interface IAccordionWrapperProps {
  title: string;
  children: React.ReactNode;
  withoutSwitch?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
}

export const AccordionWrapper = ({
  title,
  children,
  withoutSwitch,
  checked,
  defaultChecked,
}: IAccordionWrapperProps) => {
  const [isOpened, setIsOpened] = useState<boolean | undefined>(checked);

  useEffect(() => {
    setIsOpened(checked);
  }, [checked]);

  const accordionClasses = `accordion ${withoutSwitch && 'accordion--left'}`;

  return (
    <>
      <Accordion
        className={accordionClasses}
        expanded={isOpened}
        defaultExpanded={defaultChecked}
        onChange={() => setIsOpened(!isOpened)}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>{title}</AccordionSummary>
        <AccordionDetails className="accordion__details">{children}</AccordionDetails>
      </Accordion>
    </>
  );
};
