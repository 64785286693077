import { ETestStatus } from '../../interfaces';

export enum EFeatureBranchStatus {
  RUNNING = 'running',
  PARTIAL = 'partial',
  STARTING = 'starting',
  UNKNOWN = 'unknown',
  PENDING = 'pending',
  STOPPING = 'stopping',
  STOPPED = 'stopped',
}

export interface EBranchStatusData {
  id?: string;
  name: string;
  status: EFeatureBranchStatus;
  tooltip_services: [string, string][];
  launch_by?: string;
  pods: {
    started: number;
    target: number;
  };
  last_test_status?: ETestStatus;
}
