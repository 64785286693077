import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useCallback, useState } from 'react';

import { putCookie } from '../../helpers/cookie.helper';

export const Unauthorized: React.FC = () => {
  const [open, setOpen] = useState(true);

  const handleNext = useCallback(() => setOpen(false), []);

  const handleClose = useCallback(() => {
    putCookie('redirect_to', window.location.href, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      path: '/',
    });
    setOpen(false);
    window.location.href = process.env.REACT_APP_AUTH_PATH!;
    return;
  }, []);

  return (
    <div>
      <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Unauthorized'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are not authorized to view this page, but you can try to access this page with another account
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNext}>Go back</Button>
          <Button onClick={handleClose}>Switch account</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
