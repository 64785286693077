export const volumesItems = [
  {
    key: 'name',
    title: 'Name',
    type: 'text'
  },
  {
    key: 'containerPath',
    title: 'Container path',
    type: 'text'
  },
  {
    key: 'size',
    title: 'Size',
    type: 'text'
  }
] as const;

export const additionalContainerPortsItems = [
  {
    key: 'name',
    title: 'Name',
    type: 'text'
  },
  {
    key: 'port',
    title: 'Port',
    type: 'number'
  }
] as const;

export const additionalServicePortsItems = [
  {
    key: 'name',
    title: 'Name',
    type: 'text'
  },
  {
    key: 'port',
    title: 'Port',
    type: 'number'
  }
] as const;

export const additionalIngressPortsItems = [
  {
    key: 'name',
    title: 'Name',
    type: 'text'
  },
  {
    key: 'path',
    title: 'Path',
    type: 'text'
  }
] as const;

export const livenessProbeItems = [
  {
    key: 'initialDelaySeconds',
    title: 'Initial delay (sec)',
    type: 'number',
    info: 'После скольких секунд проба начнет работать'
  },
  {
    key: 'periodSeconds',
    title: 'Period (sec)',
    type: 'number',
    info: 'Переодичность срабатывания пробы'
  },
  {
    key: 'timeoutSeconds',
    title: 'Timeout (sec)',
    type: 'number',
    info: 'Таймаут пробы'
  },
  {
    key: 'successThreshold',
    title: 'Success threshold',
    type: 'number',
    info: 'Количество успешных попыток после которого счетчик неудачных попыток сбросится'
  },
  {
    key: 'failureThreshold',
    title: 'Failure threshold',
    type: 'number',
    info: 'Количество неудачных проверок, после которого сервис перезапустится'
  }
] as const;

export const readinessProbeItems = [
  {
    key: 'initialDelaySeconds',
    title: 'Initial delay (sec)',
    type: 'number',
    info: 'После скольких секунд проба начнет работать'
  },
  {
    key: 'periodSeconds',
    title: 'Period (sec)',
    type: 'number',
    info: 'Переодичность срабатывания пробы'
  },
  {
    key: 'timeoutSeconds',
    title: 'Timeout (sec)',
    type: 'number',
    info: 'Таймаут пробы'
  },
  {
    key: 'successThreshold',
    title: 'Success threshold',
    type: 'number',
    info: 'Количество успешных попыток после которого счетчик неудачных попыток сбросится'
  },
  {
    key: 'failureThreshold',
    title: 'Failure threshold',
    type: 'number',
    info: 'Количество неудачных проверок, после которого сервис перезапустится'
  }
] as const;
