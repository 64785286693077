import { useContext } from 'react';
import { EUserPermissions } from '../app/enums';
import { Unauthorized } from '../components/unauthorized/unauthorized';
import { AuthContext } from './auth-context';

export const withAccess = (permission: EUserPermissions) => {
  return <T,>(Component: React.ComponentType<T>) => {
    return (props: T) => {
      const context = useContext(AuthContext);
      if (context.permissions.includes(permission)) {
        return <Component {...props} />;
      } else {
        return <Unauthorized />;
      }
    };
  };
};
