import { Add, ArrowBack, Save } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import './header.scss';

interface IHeaderProps {
  title: string;
  mainBtnHandler: () => void;
  isSave: boolean;
  backLink?: string;
  createHandler?: () => void;
  children?: React.ReactNode;
}

export const Header = ({ title, mainBtnHandler, isSave, backLink, createHandler, children }: IHeaderProps) => {
  return (
    <header className="header">
      {backLink && (
        <span className="header__link">
          <Link to={backLink}>
            <ArrowBack />
          </Link>
        </span>
      )}
      <h2 className="header__title">{title}</h2>
      {createHandler && (
        <IconButton onClick={createHandler} className="header__plus-btn">
          <Add />
        </IconButton>
      )}
      {children}
      <Button
        onClick={mainBtnHandler}
        variant="contained"
        color="primary"
        startIcon={isSave ? <Save /> : <Add />}
        className="header__main-btn"
      >
        {isSave ? 'Save' : 'Create'}
      </Button>
    </header>
  );
};
