import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';

interface IProps {
  open: boolean;
  header: string;
  children: React.ReactNode;
  onClose: () => void;
}

export const ViewDialog = ({ open, header, children, onClose }: IProps) => {
  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{header}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
