import { Box, Chip, IconButton, InputAdornment, Snackbar, TextField, Tooltip } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { EBranchStatusData, EFeatureBranchStatus, EUserPermissions } from '../../app/enums';
import { api } from '../../helpers/api.helper';
import { ETestShowStatus, ETestStatus, IDeploymentData, ILaunchInfo } from '../../interfaces';

import { ArrowForward, MoreVert, Search, Star } from '@mui/icons-material';
import { Alert } from '@mui/lab';
import { withAccess } from '../../auth-context/can-access';
import { DialogChoose } from '../dialog/dialog';
import { DropdownMenu } from '../dropdown-menu/dropdown-menu';
import './branches.scss';
import { Link } from 'react-router-dom';

const LocalStorageSearchKey = 'k8s-utils:feature/search';
const LocalStorageFavouriteKey = 'k8s-utils:feature/favourite';

const branch_order = {
  [EFeatureBranchStatus.RUNNING]: 1,
  [EFeatureBranchStatus.PARTIAL]: 2,
  [EFeatureBranchStatus.STARTING]: 3,
  [EFeatureBranchStatus.UNKNOWN]: 4,
  [EFeatureBranchStatus.PENDING]: 5,
  [EFeatureBranchStatus.STOPPING]: 6,
  [EFeatureBranchStatus.STOPPED]: 7,
} as const;

const COLORS = {
  [EFeatureBranchStatus.RUNNING]: '#00c164',
  [EFeatureBranchStatus.PARTIAL]: '#00c164',
  [EFeatureBranchStatus.UNKNOWN]: '#439EF4',
  [EFeatureBranchStatus.PENDING]: '#439EF4',
  [EFeatureBranchStatus.STOPPED]: '#ff5272',
  [EFeatureBranchStatus.STARTING]: '#439EF4',
  [EFeatureBranchStatus.STOPPING]: '#439EF4',
} as const;

const getLastTestStatus = (branch: EBranchStatusData): ETestShowStatus =>
  (branch.last_test_status === ETestStatus.UNKNOWN
    ? ETestShowStatus.QUEUED
    : branch.last_test_status) as ETestShowStatus;

const BranchesComponent = () => {
  const [featureBranches, setFeatureBranches] = useState<EBranchStatusData[]>([]);
  const [filteredFeatureBranches, setFilteredFeatureBranches] = useState<EBranchStatusData[]>([]);
  const [favouriteBranches, setFavouriteBranches] = useState<Set<string>>(new Set());
  const [searchValue, setSearchValue] = useState('');
  const [changeStatus, setChangeStatus] = useState<{ name: string; status: EFeatureBranchStatus } | null>(null);
  const [toastMessage, setToastMessage] = useState('');

  const toFeatureBranch = (feature_branch: string) => {
    const non_feature_branches = ['rc', 'beta'];
    const feature_middle = non_feature_branches.includes(feature_branch) ? '' : '.feature';
    return `https://${feature_branch}${feature_middle}.trainingspace.online`;
  };

  const getBranchStatus = (name: string, deployments: Array<IDeploymentData>, launch_info?: ILaunchInfo) => {
    let uniq_started_pods = 0;
    let started_pods = 0;
    let target_pods = 0;

    let has_disabled_replicas = false;
    let has_unavailable_replicas = false;

    const tooltip_services: [string, string][] = [];

    for (const deployment of deployments) {
      if (!deployment.replicas) {
        has_disabled_replicas = true;
      }

      if (deployment.availableReplicas) {
        uniq_started_pods += 1;
      }

      if (deployment.unavailableReplicas) {
        has_unavailable_replicas = true;
      }

      started_pods += deployment.availableReplicas;
      target_pods += deployment.replicas;

      tooltip_services.push([deployment.name, `${deployment.availableReplicas} / ${deployment.replicas}`]);
    }

    let status: EFeatureBranchStatus = EFeatureBranchStatus.UNKNOWN;

    if (has_unavailable_replicas) {
      if (target_pods) {
        status = EFeatureBranchStatus.PENDING;
      } else {
        status = EFeatureBranchStatus.STOPPING;
      }
    } else if (started_pods === target_pods) {
      if (target_pods) {
        if (deployments.length > uniq_started_pods && has_disabled_replicas) {
          status = EFeatureBranchStatus.PARTIAL;

          target_pods += deployments.length - uniq_started_pods;
        } else {
          status = EFeatureBranchStatus.RUNNING;
        }
      } else {
        status = EFeatureBranchStatus.STOPPED;
      }
    } else if (started_pods < target_pods) {
      status = EFeatureBranchStatus.STARTING;
    } else if (started_pods > target_pods) {
      status = EFeatureBranchStatus.STOPPING;
    }

    let launch_by = launch_info?.email ? ` (${launch_info.email} - ${launch_info.up_time})` : '';

    const branch_status: EBranchStatusData = {
      id: launch_info?.id,
      name,
      status,
      tooltip_services,
      pods: { started: started_pods, target: target_pods },
      launch_by,
      last_test_status: launch_info?.last_test_status,
    };

    return branch_status;
  };

  useEffect(() => {
    {
      // restore search

      const searchHistory = localStorage.getItem(LocalStorageSearchKey);

      if (searchHistory) {
        setSearchValue(searchHistory);
      }
    }

    {
      // restore favourites

      setFavouriteBranches(new Set<string>(JSON.parse(localStorage.getItem(LocalStorageFavouriteKey))));
    }

    let destroy: () => void;

    {
      // interval request branches

      const getFeatureBranchList = async () => {
        api.feature_branch
          .list()
          .then(({ data }) => {
            const feature_branches = Object.entries(data).map<EBranchStatusData>(([env, feature_branch]) =>
              getBranchStatus(env, feature_branch.deployments, feature_branch.launch_info)
            );

            setFeatureBranches(feature_branches);
          })
          .catch((e) => setToastMessage(`Error: ${e}`));
      };

      let interval: ReturnType<typeof setInterval> | undefined;

      const startInterval = () => {
        if (!interval) {
          getFeatureBranchList();
          interval = setInterval(getFeatureBranchList, 5000);
        }
      };

      const stopInterval = () => {
        if (interval) {
          clearInterval(interval);
          interval = undefined;
        }
      };

      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          startInterval();
        } else {
          stopInterval();
        }
      };

      startInterval();

      document.addEventListener('visibilitychange', handleVisibilityChange);

      destroy = () => {
        stopInterval();
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }

    return () => destroy();
  }, []);

  useEffect(() => {
    localStorage.setItem(LocalStorageSearchKey, searchValue);
  }, [searchValue]);

  useEffect(() => {
    setFilteredFeatureBranches(
      featureBranches
        .filter((branch) => {
          const values = searchValue.split(',');
          return values.some((x) => branch.name.toLowerCase().includes(x.toLowerCase()));
        })
        .sort((a, b) => {
          const favouriteComparator = +favouriteBranches.has(b.name) - +favouriteBranches.has(a.name);
          return favouriteComparator || branch_order[a.status] - branch_order[b.status];
        })
    );
  }, [favouriteBranches, searchValue, featureBranches]);

  const toggleBranch = useCallback(() => {
    if (!changeStatus) {
      return;
    }

    const current_branch_status = filteredFeatureBranches.find((branch) => branch.name === changeStatus.name);

    if (!current_branch_status) {
      return;
    }

    if (current_branch_status.status === changeStatus.status) {
      setToastMessage(
        `Can't change  ${changeStatus.name} status to ${changeStatus.status} because it's already ${current_branch_status.status}`
      );

      setChangeStatus(null);

      return;
    }

    if (changeStatus.name) {
      if (changeStatus.status === EFeatureBranchStatus.RUNNING) {
        api.feature_branch.changeStatus(changeStatus.name, 'start');
      } else if (changeStatus.status === EFeatureBranchStatus.STOPPED) {
        api.feature_branch.changeStatus(changeStatus.name, 'stop');
      }
    }

    setChangeStatus(null);
  }, [changeStatus]);

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value.replace(/\s+/g, ''));

  const closeToast = useCallback(() => setToastMessage(''), []);

  const closeDialog = useCallback(() => setChangeStatus(null), []);

  const addToFavourite = (branch: EBranchStatusData) => {
    setFavouriteBranches((prev) => {
      const newData = new Set([...prev, branch.name]);
      localStorage.setItem(LocalStorageFavouriteKey, JSON.stringify(Array.from(newData)));
      return newData;
    });
  };

  const removeFromFavourite = (branch: EBranchStatusData) => {
    setFavouriteBranches((prev) => {
      const newData = new Set([...prev]);
      newData.delete(branch.name);
      localStorage.setItem(LocalStorageFavouriteKey, JSON.stringify(Array.from(newData)));
      return newData;
    });
  };

  return (
    <>
      <Box className="feature-branch__header">
        <h2 className="header__title">Branches</h2>
        <TextField
          className="feature-branch__search"
          label="Search"
          onChange={onChangeInput}
          variant="outlined"
          autoFocus
          value={searchValue}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {!!filteredFeatureBranches.length && (
        <Box className="box">
          <ul className="box__list">
            {filteredFeatureBranches.map((branch) => (
              <li key={branch.name} className="box__item feature-branch__item">
                {branch.id ? (
                  <Link to={`/branches/${branch.id}`} className="box__link feature-branch__link">
                    {branch.name}
                    {branch.launch_by}
                  </Link>
                ) : (
                  <div className="box__link feature-branch__link">
                    {branch.name}
                    {branch.launch_by}
                  </div>
                )}
                <Box className="feature-branch__info">
                  <Tooltip
                    title={favouriteBranches.has(branch.name) ? 'Удалить из избранного' : 'Добавить в избранное'}
                  >
                    <Star
                      color="info"
                      className={`feature-branch__item-star ${
                        favouriteBranches.has(branch.name) ? 'feature-branch__item-star--active' : ''
                      }`}
                      onClick={() =>
                        favouriteBranches.has(branch.name) ? removeFromFavourite(branch) : addToFavourite(branch)
                      }
                    />
                  </Tooltip>
                  {branch.last_test_status && (
                    <Tooltip classes={{ tooltip: 'test-status-tooltip' }} title="Last test status">
                      <Chip
                        className={`feature-branch__test-status feature-branch__test-status--${getLastTestStatus(
                          branch
                        )}`}
                        label={getLastTestStatus(branch)}
                      />
                    </Tooltip>
                  )}
                  <Tooltip
                    classes={{ tooltip: 'service-tooltip' }}
                    title={
                      <div className="service-title">
                        <p className="service-title__main">Services running</p>
                        <ul className="service-title__list">
                          {branch.tooltip_services.map(([left, right]) => (
                            <li key={left + right} className="service-title__item">
                              <span>{left}</span>
                              <span>{right}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    }
                  >
                    <Chip
                      style={{ backgroundColor: COLORS[branch.status] }}
                      className="box__chip"
                      label={
                        branch.status === EFeatureBranchStatus.STOPPED
                          ? branch.status
                          : `${branch.status} ${branch.pods.started} / ${branch.pods.target}`
                      }
                    />
                  </Tooltip>
                  <DropdownMenu
                    buttonElement={<MoreVert />}
                    items={[
                      {
                        text: branch.status === EFeatureBranchStatus.STOPPED ? 'Запустить' : 'Остановить',
                        onClick: () => {
                          setChangeStatus({
                            name: branch.name,
                            status:
                              branch?.status === EFeatureBranchStatus.STOPPED
                                ? EFeatureBranchStatus.RUNNING
                                : EFeatureBranchStatus.STOPPED,
                          });
                        },
                      },
                    ]}
                  />
                  <IconButton href={toFeatureBranch(branch.name)} target="_blank" rel="noopener noreferrer">
                    <ArrowForward />
                  </IconButton>
                </Box>
              </li>
            ))}
            <DialogChoose
              header={
                changeStatus?.status === EFeatureBranchStatus.STOPPED
                  ? 'Остановить feature branch?'
                  : 'Запустить feature branch?'
              }
              onClose={closeDialog}
              open={!!changeStatus}
              onSubmit={toggleBranch}
              textClose={'Нет'}
              textOk={'Да'}
            >
              <p>{changeStatus?.name}</p>
            </DialogChoose>
          </ul>
        </Box>
      )}
      <Snackbar
        className="toast"
        onClose={closeToast}
        open={!!toastMessage}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={closeToast} severity="warning">
          <p className="feature-branch__toast">{toastMessage}</p>
        </Alert>
      </Snackbar>
    </>
  );
};

export const BranchesPage = withAccess(EUserPermissions.FEATURE_BRANCHES)(BranchesComponent);
