import { Add, DeleteOutline, InfoOutlined } from '@mui/icons-material';
import { Autocomplete } from '@mui/lab';
import { Box, Button, Divider, IconButton, Paper, Switch } from '@mui/material';
// import 'ace-builds/src-noconflict/mode-json';
// import 'ace-builds/src-noconflict/snippets/json';
// import 'ace-builds/src-noconflict/theme-dracula';
// import 'ace-builds/webpack-resolver';
import { ElementType, Fragment, useContext, useState } from 'react';
import AceEditor from 'react-ace';
import { AccordionWrapper } from '../accordion-wrapper/accordion-wrapper';
import { MyServiceConfigSwitch } from '../my-service-config-switch/my-service-config-switch';
import { MyTextFieldSwitch } from '../my-text-field-switch/my-text-field-switch';
import { MyTextField, MyTextFieldProps } from '../my-text-field/my-text-field';
import {
  additionalContainerPortsItems,
  additionalIngressPortsItems,
  additionalServicePortsItems,
  livenessProbeItems,
  readinessProbeItems,
  volumesItems,
} from './service-config-items';
import './service-config.scss';
import { ServiceContext } from './service-context';

export const ServiceConfig = ({ availableNodeSelectorList }: { availableNodeSelectorList: string[] }) => {
  const service = useContext(ServiceContext).editingService;

  return (
    <div className="service-config">
      <Box className="box service-config__box">
        <Paper elevation={1} className="service-config__paper">
          <div className="service-config__block">
            <div className="service-config__title-container">
              <p className="service-config__title">Common</p>
            </div>

            <MyTextField
              label={'Container port'}
              defaultValue={service.config.containerPort}
              onChangeValue={(value) => (service.config.containerPort = +value)}
              type={'number'}
              icon={<InfoOutlined />}
              icon_tooltip={'Внутренний (главный) порт контейнера'}
            />
            <MyTextField
              label={'Replicas'}
              defaultValue={service.config.replicaCount}
              onChangeValue={(value) => (service.config.replicaCount = +value)}
              type={'number'}
            />
            <Autocomplete
              options={availableNodeSelectorList}
              multiple={false}
              defaultValue={service.config.nodeSelector || null}
              onChange={(_, value) => (service.config.nodeSelector = value as string)}
              renderInput={(params) => <MyTextField {...params} label={'Node selector'} />}
            />
            <MyTextField
              label={'Image'}
              defaultValue={service.image}
              onChangeValue={(value) => (service.image = value)}
              icon={<InfoOutlined />}
              icon_tooltip={
                'Берем ссылку на проект, заменяем `https://` на `registry.`, добавляем `:latest-$env`\nПример: `https://gitlab.com/gitlab-org/protect/general` -> `registry.gitlab.com/gitlab-org/protect/general:latest-beta`'
              }
            />
            <MyTextField
              label={'Healthcheck path'}
              defaultValue={service.config.healthCheckPath}
              onChangeValue={(value) => (service.config.healthCheckPath = value)}
              icon={<InfoOutlined />}
              icon_tooltip={'Путь для проверки состояние сервиса'}
            />
          </div>
          <Divider orientation="vertical" flexItem />
          <div className="service-config__block">
            <div className="service-config__title-container">
              <div className="service-config__switch-container">
                <p className="service-config__title">Ingress</p>
                <Switch
                  color="primary"
                  defaultChecked={service.config.ingress.enabled}
                  onChange={(_, checked) => (service.config.ingress.enabled = checked)}
                />
              </div>
              <div className="service-config__switch-container">
                <p className="service-config__title">tls</p>
                <Switch
                  color="primary"
                  defaultChecked={service.config.ingress.tls}
                  onChange={(_, checked) => (service.config.ingress.tls = checked)}
                />
              </div>
            </div>
            <MyTextField
              label={'Domain'}
              defaultValue={service.config.domain}
              onChangeValue={(value) => (service.config.domain = value)}
            />
            <MyTextField
              label={'Paths'}
              defaultValue={service.config.ingress.paths.join(',')}
              onChangeValue={(value) => (service.config.ingress.paths = value.split(','))}
              icon={<InfoOutlined />}
              icon_tooltip={'Путь по которому сервис будет доступен в интернете\n\nПример: http://exapmle.com/`$path`'}
            />
            <div className="service-config__ace-container">
              <fieldset className="service-config__ace-fieldset">
                <legend className="service-config__ace-legend">Annotations</legend>
                <AceEditor
                  className="service-config__ace-editor"
                  mode="json"
                  theme="dracula"
                  width={'100%'}
                  height={''}
                  maxLines={Infinity}
                  defaultValue={service.config.ingress.annotations}
                  onChange={(value) => (service.config.ingress.annotations = value)}
                />
              </fieldset>
            </div>
          </div>
        </Paper>
      </Box>
      <Box>
        <ServiceConfigArrayAccordion title={'Volumes'} values={service.config.volumes} items={volumesItems} />

        <ServiceConfigArrayAccordion
          title={'Additional container ports'}
          values={service.config.additionalContainerPorts}
          items={additionalContainerPortsItems}
        />

        <ServiceConfigArrayAccordion
          title={'Additional service ports'}
          values={service.config.additionalServicePorts}
          items={additionalServicePortsItems}
        />

        <ServiceConfigArrayAccordion
          title={'Additional ingress ports'}
          values={service.config.additionalIngressPorts}
          items={additionalIngressPortsItems}
        />

        <AccordionWrapper title={'Environment'} withoutSwitch checked={service.config.environment.length > 2}>
          <AceEditor
            mode="json"
            theme="dracula"
            width={'100%'}
            height={''}
            setOptions={{ useWorker: false }}
            className="service-config__ace-editor"
            maxLines={Infinity}
            defaultValue={service.config.environment}
            onChange={(value) => (service.config.environment = value)}
          />
        </AccordionWrapper>

        <div className="service-config__accordion">
          <MyTextFieldSwitch />
        </div>
        <div className="service-config__accordion">
          <MyServiceConfigSwitch
            title={'Liveness probe'}
            value={service.config.livenessProbe}
            items={livenessProbeItems}
          />
        </div>
        <div className="service-config__accordion">
          <MyServiceConfigSwitch
            title={'Readiness probe'}
            value={service.config.readinessProbe}
            items={readinessProbeItems}
          />
        </div>
      </Box>
    </div>
  );
};

interface IServiceConfigArrayAccordion<T extends string> {
  title: string;
  values: Array<{ [key in T]: string | number }>;
  items: IServiceConfigItemAccordion<T>['items'];
}

const ServiceConfigArrayAccordion = <T extends string>({
  title,
  values: init_values,
  items,
}: IServiceConfigArrayAccordion<T>) => {
  const [values, setValues] = useState(init_values);

  return (
    <>
      <AccordionWrapper title={title} withoutSwitch checked={!!values.length}>
        <Button
          startIcon={<Add />}
          onClick={() => {
            init_values.push({} as { [key in T]: string | number });
            setValues([...init_values]);
          }}
        >
          Add
        </Button>
        {values.map((value, index) => (
          <div key={'' + index + value[items[0].key]}>
            <div className="accordion-wrapper-items">
              <IconButton
                onClick={() => {
                  init_values.splice(index, 1);
                  setValues([...init_values]);
                }}
              >
                <DeleteOutline />
              </IconButton>
            </div>
            <ServiceConfigItemAccordion value={value} keySalt={index} items={items} />
          </div>
        ))}
      </AccordionWrapper>
    </>
  );
};

interface IServiceConfigItemAccordion<T extends string> {
  title?: string;
  keySalt?: string | number;
  value: { [key in T]?: string | number };
  checked?: boolean;
  defaultChecked?: boolean;
  items: Readonly<
    Array<{
      key: T;
      title: string;
      type: 'text' | 'number';
      info?: string;
    }>
  >;
}

export const ServiceConfigItemAccordion = <T extends string>({
  title,
  keySalt,
  value,
  items,
  checked,
  defaultChecked,
}: IServiceConfigItemAccordion<T>) => {
  const Wrapper: ElementType = title ? AccordionWrapper : Fragment;
  const props = title ? { title, checked, defaultChecked } : {};
  return (
    <Wrapper {...props}>
      <div className="service-config__accordion-item">
        {items.map(({ key, title, type, info }) => {
          let additional_props: MyTextFieldProps = {};

          if (info) {
            additional_props.icon = <InfoOutlined />;
            additional_props.icon_tooltip = info;
          }

          return (
            <MyTextField
              key={(keySalt ?? '') + key}
              label={title}
              {...additional_props}
              type={type}
              defaultValue={value[key]}
              onChangeValue={(newValue) => {
                if (type === 'number') {
                  value[key] = +newValue;
                } else {
                  value[key] = newValue;
                }
              }}
            />
          );
        })}
      </div>
    </Wrapper>
  );
};
