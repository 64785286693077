import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { ElementType, FC, ReactElement, useRef, useState } from 'react';
import './dropdown-menu.scss';

export interface IDropdownMenuItem {
  text: string;
  onClick: () => void;
}

interface IProps {
  buttonElement: string | ReactElement;
  items: Array<IDropdownMenuItem>;
  isLoading?: boolean;
}

export const DropdownMenu: FC<IProps> = ({ buttonElement, items, isLoading }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleClick = (onClick?: () => void) => {
    setOpen(false);
    onClick?.();
  };

  const buttonClassNames = `dropdown-menu-button ${isLoading && 'dropdown-menu-button--with-loader'}`;

  const ButtonWrapper: ElementType = typeof buttonElement === 'string' ? Button : IconButton;
  return (
    <>
      <ButtonWrapper
        className={buttonClassNames}
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={() => {
          setOpen((prevOpen) => !prevOpen);
        }}
      >
        {buttonElement}
        {isLoading && <CircularProgress size={20} className="dropdown-menu-button__loader" />}
      </ButtonWrapper>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        className="popper"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => handleClick()}>
                <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
                  {items.map(({ text, onClick }) => (
                    <MenuItem key={text} onClick={() => handleClick(onClick)} className="popper__item">
                      {text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
