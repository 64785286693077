import { Switch } from '@mui/material';
import { useState } from 'react';
import { ServiceConfigItemAccordion } from '../service/service-config';

interface IMyServiceConfigSwitch<T extends string> {
  title: string;
  value: any;
  items: Readonly<
    Array<{
      key: string;
      title: string;
      type: 'text' | 'number';
      info?: string;
    }>
  >;
}

export const MyServiceConfigSwitch = <T extends string>({ title, value, items }: IMyServiceConfigSwitch<T>) => {
  const [isChecked, setIsChecked] = useState(value.enabled);

  return (
    <>
      <Switch
        color="primary"
        checked={isChecked}
        onChange={(_, checked) => {
          value.enabled = checked;
          setIsChecked(value.enabled);
        }}
      />

      <ServiceConfigItemAccordion
        checked={isChecked}
        defaultChecked={isChecked}
        title={title}
        value={value}
        items={items}
      />
    </>
  );
};
